import React, { useState, useRef, useEffect } from "react"
import { IconButton, TextareaAutosize } from "@material-ui/core"
import { AddCircle, AttachFile, Mic, Send, Stop } from "@material-ui/icons"
import WebSocketClient from "./WebSocketClient"
import { useRecoilState, useRecoilValue } from "recoil"
import { userAuthState } from "../recoil/atoms"
import botrunUseAudioRecorder from "./BotrunUseAudioRecorder"
import { onSendMessageAtom } from "../recoil/atoms"
import { useParams } from "react-router-dom"
import useSessionId from "../hooks/useSessionId"
import { verifyRecaptcha } from "../services/apiService"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface ChatInputProps {
  onSendMessage: (message: string, isUserInput: boolean) => void
  onUploadFiles: (files: FileList, sessionId: string) => void
  messages: Array<{ message: string; isUserMessage: boolean }>
  setMessages: React.Dispatch<
    React.SetStateAction<Array<{ message: string; isUserMessage: boolean }>>
  >
  params: string | null
  webSocketClient: WebSocketClient | null
  isAssistantSpeaking: boolean
  setIsAssistantSpeaking: React.Dispatch<React.SetStateAction<boolean>>
  externalInput: string | null
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  onUploadFiles,
  messages,
  setMessages,
  params,
  webSocketClient,
  isAssistantSpeaking: isAssistantAnswering,
  setIsAssistantSpeaking,
  externalInput,
}) => {
  const user = useRecoilValue(userAuthState)
  const jwtToken = user?.token
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [inputValue, setInputValue] = useState("")
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { startRecording, stopRecording, fileData } = botrunUseAudioRecorder()
  const [isRecording, setIsRecording] = useState(false)
  const [, setOnSendMessage] = useRecoilState(onSendMessageAtom)
  const [isResetDisabled, setIsResetDisabled] = useState(false)
  const urlParams = useParams()
  const { data: sessionId, refetch } = useSessionId(jwtToken, urlParams.sessionId)
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px"
    }
  }, [inputValue])

  useEffect(() => {
    if (externalInput) {
      onSendMessage(externalInput, true)
      setIsAssistantSpeaking(true)
      manageWebSocket(externalInput)
      setInputValue("")
    }
  }, [externalInput]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOnSendMessage(() => (message: string, isUserInput: boolean) => {
      if (isUserInput && webSocketClient) {
        setIsAssistantSpeaking(true)
        manageWebSocket(message)
      }
      onSendMessage(message, isUserInput)
    })
  }, [onSendMessage, webSocketClient, jwtToken])

  const handleReset = () => {
    onSendMessage("reset", true)
    manageWebSocket("reset")
    setMessages([])
    refetch()
  }

  const handleSend = () => {
    if (inputValue) {
      onSendMessage(inputValue, true)
      setIsAssistantSpeaking(true)
      manageWebSocket(inputValue)
      setInputValue("")
    }
  }

  // const handleSend = async () => {
  //   if (!inputValue) return

  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available.')
  //     alert('Captcha not ready, try again')
  //     return
  //   }

  //   const token = await executeRecaptcha("submit_message")
  //   const recaptchaResult = await verifyRecaptcha(token)

  //   if (recaptchaResult.isSuccess) {
  //     onSendMessage(inputValue, true)
  //     setIsAssistantSpeaking(true)
  //     manageWebSocket(inputValue)
  //     setInputValue("")
  //   } else {
  //     alert(recaptchaResult.message)
  //   }
  // }

  const handleStop = () => {
    if (isAssistantAnswering) {
      setIsAssistantSpeaking(false)
      if (webSocketClient) {
        webSocketClient.disconnect()
      }
    }
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIsAssistantSpeaking(true)
      onUploadFiles(e.target.files, sessionId)
      e.target.value = ""
    }
  }

  const handleRecord = async () => {
    if (isRecording) {
      stopRecording()
      setIsRecording(false)
    } else {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true })
        startRecording()
        setIsRecording(true)
      } catch (err) {
        alert("請檢查麥克風權限")
        console.error("麥克風權限錯誤:", err)
      }
    }
  }

  useEffect(() => {
    if (fileData) {
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(fileData)
      onUploadFiles(dataTransfer.files, sessionId)
    }
  }, [fileData]) // eslint-disable-line react-hooks/exhaustive-deps

  const manageWebSocket = (message: string) => {
    if (message == "reset") {
      setIsResetDisabled(true)
    }
    if (webSocketClient) {
      webSocketClient.disconnect()
      webSocketClient.connect()
      webSocketClient.sendMessage({
        user_input: message,
        jwt_token: jwtToken,
        session_id: sessionId,
      })
    }
    setIsResetDisabled(false)
  }

  return (
    <div style={{ display: "flex", alignItems: "center", padding: 8 }}>
      {process.env.REACT_APP_USE_SSO !== "True"}
      {process.env.REACT_APP_PEDIA_BOT_ONLY !== "True" ? (
        <>
          <IconButton size="medium" onClick={handleReset} disabled={isResetDisabled}>
            <AddCircle />
          </IconButton>
          <IconButton size="medium" onClick={() => fileInputRef.current?.click()}>
            <AttachFile />
          </IconButton>
          <input
            ref={fileInputRef}
            type="file"
            multiple
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </>
      ) : (
        <>
          {/* 教育百科版本 Botrun 需隱藏 reset 等功能按鈕 */}
        </>
      )}

      <TextareaAutosize
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyPress={e => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            handleSend()
          }
        }}
        minRows={1}
        maxRows={8}
        placeholder="Type your message..."
        style={{
          flexGrow: 1,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 15,
          paddingLeft: 15,
          paddingRight: 15,
          resize: "none",
          overflow: "auto",
          fontSize: "1.1rem",
          lineHeight: "1.5rem",
          color: "white",
          maxHeight: "8rem",
          overflowY: "auto",
        }}
        ref={textAreaRef}
      />
      <IconButton size="medium" onClick={handleRecord}>
        {isRecording ? <Stop /> : <Mic />}
      </IconButton>
      <IconButton
        size="medium"
        onClick={isAssistantAnswering ? handleStop : handleSend}
        style={{ color: isAssistantAnswering ? "red" : "inherit" }} // 根據isAssistantAnswering的狀態設定顏色
      >
        {isAssistantAnswering ? <Stop /> : <Send />}
      </IconButton>
    </div>
  )
}

export default ChatInput